'use strict';

/**
 * Webcore project Support/Bug-report forms
 * version 3.0.3
 * 21.02.2019
 */

$(document).ready(function(){
    let files = 1;
    let maxFiles = (!!maxUploadFilesNum && maxUploadFilesNum > 0) ? maxUploadFilesNum : 0;
    let fileSizes = Array();
    let maxFileSize = 20*1024*1024; //20Mb

    $('.pre-block input[type=button]').click(function () {
        $('.pre-block').hide();
        $('.form-block').show();

        $('.form-block input[name=inquiry]').attr('value', $('.pre-block input[name=inquiry]:checked').val());
    });

    $('.pre-block .inquiry span').click(function () {
        $(this).parent().find('input[type=radio]').click();
    });

    $('.pre-block input[type=radio]').click(function() {
        $('.pre-block input[type=button]').enable();
    });

    $(document).on('change', '.support-form .file-field', function(){
        if (checkExt($(this).val().split('.').pop().toLowerCase()) == false || checkFileSizes($(this).attr('id').substr(4,1), this.files[0].size) == false) {
            fileSizes[$(this).attr('id').substr(4,1)] = 0;
            $(this).val('');
        } else {
            if (files < maxFiles && $(this).attr('id').substr(4,1) == $('.screens-fields').children('li').length) {
                files++;
                $('<li><div><input type="file" class="file-field" name="images[]" id="file' + files + '" value="" accept="image/*, video/*"></div></li>').appendTo($('.screens-fields'));
            }
        }
    });

    function checkExt(ext) {
        if($.inArray(ext, ['gif','png','jpg','jpeg','bmp', 'mov', 'm4v', '3gp', 'mp4']) == -1) {
            _alert(getLangString('support', 'alert_file_format', 'The attached file has an invalid format. Use the following file formats:') + ' jpg/jpeg, png, gif, bmp, mov, m4v, mp4, 3gp.');
            return false;
        } else {
            return true;
        }
    }

    function checkFileSizes(id, size) {
        if (id > -1) {
            fileSizes[id] = size;
        }
        let allfiles = 0;
        for (let i=1; i < fileSizes.length; i++) {
            if (fileSizes[i] >= 0) {
                allfiles += fileSizes[i];
            }
        }
        if (allfiles > maxFileSize) {
            _alert(getLangString('support', 'alert_size', 'The size of selected files exceeds %s. Please, remove or change some files.', bytesToSize(maxFileSize, 1)));
            return false;
        } else {
            return true;
        }
    }

    $(document).on('focusin', '.support-form textarea.text-field', function(){
        $(this).removeClass('error-field');
    });


    $('.support-form input.text-field').focusin(function() {
        $(this).removeClass('error-field');
    });

    $('.support-form').submit(function(event){

        let errstr = '';
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ($('.support-form #name').val().length < 2) {
            errstr += getLangString('support', 'alert_validation_name', 'Field "Your Name" must contain at least 2 symbols.') + ' ';
            $('.support-form #name').addClass('error-field');
        }
        if (!re.test($('.support-form #email').val())) {
            if (errstr.length > 0) errstr += '\n';
            errstr += getLangString('support', 'alert_validation_email', 'Field "Your Email" must contain a valid email.') + ' ';
            $('.support-form #email').addClass('error-field');
        }
        if ($('.support-form #message').val().length < 5) {
            if (errstr.length > 0) errstr += '\n';
            errstr += getLangString('support', 'alert_validation_text', 'Field "Your Message" must contain at least 5 symbols.') + ' ';
            $('.support-form #message').addClass('error-field');
        }

        if (errstr.length > 0) {
            $('.text-field').blur();

            if (event.preventDefault) {
                event.preventDefault();
            } else {
                event.returnValue = false;
            }

            _alert(errstr);
        } else {
            $('.submit-block').focus(); //change focus to some element to prevent keyboard visible state.

            $('.ironCurtain').show();
            $('.progressBar').show();

            $(this).ajaxSubmit({
                forceSync: true,
                uploadProgress: function(event, position, total, percentComplete) {
                    //centring again after keyboard slowly goes out
                    if (percentComplete < 99) {
                        if (!!position) {
                            $('#progressStatus').html(getLangString('support', 'file_uploading_1', 'Uploading') + ' <span id="pos">' + bytesToSize(position, 1) + '</span> ' + getLangString('support', 'file_uploading_2', 'of')  +' <span id="total">' + bytesToSize(total, 1) + '</span>');
                        } else {
                            $('#progressStatus').html(getLangString('support', 'file_uploading_1', 'Uploading'));
                        }
                    } else {
                        setTimeout(function() {
                            $('#progressStatus').html( getLangString('support', 'file_uploading_3', 'It\'s almost done.') + '<br>' + getLangString('support', 'file_uploading_4', 'Please, wait a little more.'));
                        }, 5000);
                    }
                    $('.progressBar .bar').css('width', Math.abs(percentComplete * 2.7) + 'px');

                },
                // dataType identifies the expected content type of the server response
                dataType:  'json',
                // success identifies the function to invoke when the server response
                // has been received
                error: function(xhr, textStatus, errorThrown) {
                    $('.progressBar #pos').html('');
                    $('.progressBar #total').html('');
                    $('.progressBar .bar').css('width', '0px');
                    $('.ironCurtain').hide();
                    $('.progressBar').hide();
                    _alert(getLangString('support', 'alert_no_connection', 'Internet connection was lost. Please try to resend the form.'));
                },
                success: function (data) {

                    $('#progressStatus').html('100%');

                    setTimeout(function() {
                        $('.ironCurtain').hide();
                        $('.progressBar').hide();

                        let mess_obj = data;
                        if (mess_obj.sent != 1 ||  data == '') {
                            _alert(getLangString('support', 'alert_common_validation', 'Please fill all form fields.'));
                        } else {
                            $('.text-field').blur();
                            $('.mess-sent-box .name').html(mess_obj.name);
                            $('.mess-sent-box .email').html(mess_obj.email);
                            $('.mess-sent-box .mes-block').html(mess_obj.message);
                            $('.mess-sent-box').show();
                            $('.form-block').hide();
                            $('html, body').animate({
                                scrollTop: $('.mess-sent-box').offset().top
                            }, 500);
                        }
                    }, 500);
                }
            });
            return false;
        }
    });

    $('.mess-sent-box input[type=button]').click(function() {
        document.location.reload(true);
    });
});

function bytesToSize(bytes, precision)
{
    let kilobyte = 1024;
    let megabyte = kilobyte * 1024;
    let gigabyte = megabyte * 1024;
    let terabyte = gigabyte * 1024;

    if ((bytes >= 0) && (bytes < kilobyte)) {
        return bytes + ' B';

    } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
        return (bytes / kilobyte).toFixed(precision) + ' KB';

    } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
        return (bytes / megabyte).toFixed(precision) + ' MB';

    } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
        return (bytes / gigabyte).toFixed(precision) + ' GB';

    } else if (bytes >= terabyte) {
        return (bytes / terabyte).toFixed(precision) + ' TB';

    } else {
        return bytes + ' B';
    }
}


$(window).on('load', function(){
    if (isiPhone() == true) {
        let viewportmeta = document.querySelector('meta[name="viewport"]');
        if (viewportmeta) {
            viewportmeta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
            document.body.addEventListener('gesturestart', function () {
                viewportmeta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
            }, false);
        }
    }
});

function isiPhone(){
    let isiPad = navigator.userAgent.match(/iPad/i) != null;
    let isiPhone = navigator.userAgent.match(/iPhone/i) != null;

    return (
        (isiPad == true) ||
        (isiPhone == true)
    );
}

function _alert(message) {
    setTimeout(function() {
        alert(message);
    }, 500);
}

function getLangString(formName, paramName, defaultValue, replaceValue) {
    let langStr = (langStrings.forms[formName][paramName] != undefined) ? langStrings.forms[formName][paramName] : defaultValue;
    if (!!replaceValue) {
        langStr = langStr.replace('%s', replaceValue);
    }
    return langStr;
}
